@import 'Styles/Medborgarskolan/includes';

/**
*
* SliderCardNews
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SliderCardNews.Base.module.scss';

.SliderCardNews {
    $root: &;

    margin-top: 0 !important;
    margin-bottom: 0 !important;
    background: $blue-20;

    &__Container {
        padding-top: 48px;
        padding-bottom: 28px;

        @include media(md) {
            padding-top: 72px;
            padding-bottom: 68px;
        }

        #{$root}--NoTopSpacing & {
            padding-top: 0 !important;
        }
    }

    &__Link {
        @include u-button(primary);

        &::after {
            content: '';
            display: inline-block;
            width: 15px;
            height: 12px;
            margin-left: 10px;
            background: url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow--white.svg')
                no-repeat center center;
            background-size: contain;
            transition: background 0.3s ease-in-out;
        }

        svg {
            display: none;
        }
    }
}
